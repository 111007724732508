.wrapper {
  width: 100%;
  border-radius: 16px;
  background-color: var(--secondary-1);
  padding: 10px;
  margin-top: 14px;
}

.container {
  width: 100%;
  padding: 10px;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 360px;
  margin-bottom: 10px;

  & img {
    width: 100%;
    max-width: max-content;
    max-height: 100%;
    object-fit: contain;
    border-radius: 12px;
  }
}

.footer {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & button {
    width: 172px;
  }
}

.title,
.text {
  line-height: 0.5;
  display: block;
}

.title {
  color: var(--secondary-7);
  font-size: 20px;
}

.text {
  color: var(--secondary-5);
  margin-top: 14px;
}

.btn svg path {
  fill: var(--secondary-7);
}

.uploadContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.uploadInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.draggerTitle {
  color: var(--primary-6);
  margin-top: 18px;
  line-height: 1;
}

.draggerText {
  color: var(--secondary-4);
  margin-top: 0.5rem;
}

.icon path:first-of-type {
  fill: var(--secondary-1);
}

.icon path:nth-child(2) {
  fill: var(--secondary-3);
}

.icon path:last-of-type {
  fill: var(--secondary-7);
}

.videoIcon {
  width: 48px;
  height: 36px;
}

.displayIcon {
  transform: scale(1.2);
}

.errorWrapper {
  width: 100%;
  height: 430px;
  border-radius: 12px;
  background-color: var(--red-1);
  border: 1px solid var(--red-3);
  box-shadow: 0px 8px 20px 0px rgba(223, 60, 109, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 14px;
}

.errorIconContainer {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--red-5);
  box-shadow: 0px 0px 0px 4px #ffd9e6;
  margin-bottom: 14px;

  & svg {
    width: 20px;
    height: 20px;
  }

  & path {
    fill: var(--white);
  }
}

.errorTitle {
  color: var(--red-5);
  line-height: 1;
}

.errorText {
  display: block;
  color: var(--secondary-5);
  line-height: 1.6;
  margin-top: 10px;
}

.errorBtn {
  min-height: 36px;
  margin-top: 36px;

  & svg {
    width: 18px;
    height: 18px;
  }

  &::before {
    content: "";
    display: block;
    width: 24px;
    height: 1px;
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-3);
  }
}
