.page > div {
  max-width: 1100px !important;
}

.tabHeader {
  width: calc(100% - 230px);
  position: fixed;
  background-color: var(--white);
  height: 52px;
  border-bottom: var(--border-base);
  box-shadow: 0px 2px 15px rgba(30, 19, 92, 0.03),
    0px 2px 2px rgba(30, 19, 92, 0.03);
  top: 60px;
  z-index: 12;
}

.tabs {
  margin-top: 5.75rem;
}

.tabs > div:first-child {
  background-color: transparent;
  padding: 0;
  border: none;
  height: 52px;
  margin-bottom: 36px;
  position: fixed;
  top: 60px;
  z-index: 12;
  width: 100%;
  max-width: 1100px;

  & div[class="ant-tabs-nav-list"] {
    width: 100%;
    justify-content: space-between;
  }

  & > div,
  & div[class="ant-tabs-tab ant-tabs-tab-active"] {
    box-shadow: none;
    background-color: transparent;
  }

  & div[class="ant-tabs-tab"],
  div[class="ant-tabs-tab ant-tabs-tab-active"] {
    margin-bottom: 0 !important;
    width: 100%;
    justify-content: center;
    border-radius: 0;
  }

  & div[class="ant-tabs-tab"] {
    border-bottom: 2px solid transparent;
  }

  div[class="ant-tabs-tab ant-tabs-tab-active"] {
    border-bottom: 2px solid var(--primary-4);
    transition: border 0.2s linear;
  }
}

.container {
  padding: 36px;
  background-color: var(--white);
  border: 1px solid var(--secondary-3);
  border-radius: 12px;
  margin-bottom: var(--size-lg);
  justify-content: space-between;
  margin-top: 82px;

  & .link {
    font-weight: 500;
    font-size: 14px;
    color: var(--primary-4);
    text-decoration: underline;
    margin-top: 0.5rem;
    display: block;
  }
}

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4rem;
}

.divider {
  width: calc(100% + 72px);
  margin: 36px 0 36px -36px;
}

.secondaryTitle {
  margin-bottom: 0.5rem !important;
  color: var(--primary-6) !important;
  font-size: 18px !important;
}

.categoryText {
  color: var(--secondary-5);
  line-height: 24px;
  display: block;
}

.delete {
  color: var(--red-5);
  cursor: pointer;
  background-color: var(--white);
  padding: 5px;
  border: var(--border-base);
  border-radius: var(--size-radius);
}

.deleteKeyword {
  margin-right: -1rem;
}

.table {
  border: var(--border-base);
  padding: 1rem;
  border-radius: 12px;
}

.title:not(:first-of-type) {
  margin-top: var(--size-lg);
}

.title {
  line-height: 1 !important;
}

.type {
  margin-bottom: 0;
}

.type label:not(:last-of-type) {
  margin-bottom: 10px;
}

.subtext {
  color: var(--secondary-5);
  margin-bottom: 1rem;
  display: inline-block;
  line-height: 2;
}

.text {
  color: var(--secondary-4);
  line-height: 1.5;
}

.tree {
  margin-top: 12px;
  border-radius: 10px;
  border: 1px solid var(--secondary-2);
  padding: 0 16px;

  & span[class*="ant-tree-switcher"] {
    display: flex;
    align-items: center;
  }

  & div[class*="ant-tree-treenode"] {
    padding: 0;
    position: relative;
  }

  & div[class*="ant-tree-treenode"]:not(:last-of-type)::after {
    content: "";
    display: block;
    width: calc(100% + 32px);
    height: 1px;
    background-color: var(--secondary-2);
    position: absolute;
    margin-left: -16px;
    bottom: 0;
  }
}

.nodeName {
  color: var(--secondary-7);
  flex-basis: 150px;
}

.iconContainer {
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
}

.editContainer {
  background-color: var(--primary-2);
  width: 20px;
  height: 20px;
  margin-left: -0.25rem;
}

.okContainer {
  background-color: var(--primary-4);
  left: 0.5rem;
  width: 1rem;
  height: 1rem;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}

.icon {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  & svg {
    width: 12px;
    transition: transform 0.3s ease-in-out;
  }
}

.up {
  border: 1px solid var(--primary-3);
  background-color: var(--primary-2);
  box-shadow: 0px 2px 15px 0px rgba(30, 19, 92, 0.03),
    0px 2px 2px 0px rgba(30, 19, 92, 0.03);
  transition: all 0.3s ease-in-out;

  & svg path {
    fill: var(--primary-4);
  }

  & svg {
    transform: rotate(180deg);
  }
}

.down {
  border: 1px solid var(--secondary-2);
  background-color: var(--white);
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
}

.categoryTabs {
  padding: 0 36px 36px;
  position: relative;
}

.editCol {
  position: relative;
  width: 200px;
  display: flex;
  align-items: center;
}

.editCol:not(:first-of-type)::after {
  content: "";
  position: absolute;
  display: block;
  width: 1px;
  height: 14px;
  background-color: var(--secondary-2);
  left: -10%;
  z-index: 0;
}

.editLabel {
  width: 80px;
  text-align: right;
  margin-right: 8px;
  color: var(--secondary-6);
}

.btnContainer {
  display: flex;
  gap: 6px;
}

.resetWrapper {
  position: absolute;
  width: 54px;
  height: 100%;
  right: -16px;
}

.resetWrapperActive {
  justify-content: center;
  background-color: var(--secondary-1);
  border-left: 1px solid var(--secondary-2);
}

.resetIconContainer {
  width: 28px;
  height: 28px;
  justify-content: center;
  background-color: var(--white);
  border: 1px solid var(--secondary-2);
  border-radius: 10px;
  box-shadow: 0px 2px 2px 0px rgba(30, 19, 92, 0.03);
}

.btn {
  min-height: 28px;
  margin-bottom: 4px;

  & svg {
    width: 14px;
  }
}

.categoryContainer {
  padding: 0;
  border: 1px solid var(--secondary-2);
  border-top: none;
  max-width: 1086px;
  max-height: calc(100vh - 285px);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  top: -12px;
  z-index: 99;
}

.categoryHeader {
  background-color: var(--white);
  padding: 36px;
  position: sticky;
  z-index: 10;
  width: 100%;
  margin: 0 auto;
  top: 0;
  border-radius: 12px 12px 0 0;
  border: 1px solid var(--secondary-2);
  border-bottom: 1px solid var(--secondary-3);
}

.search {
  position: sticky;
  top: 155px;
  z-index: 10;
  background-color: var(--white);
  padding: 36px 36px 10px 36px;
  transition: all 0.3s ease-in-out;

  &::after {
    content: "";
    position: absolute;
    left: -1px;
    top: 48px;
    background-color: var(--white);
    display: block;
    height: 0;
    width: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1;
    border-right: 1px solid var(--secondary-2);
    border-left: 1px solid var(--secondary-2);
    box-shadow: 0px 37px 10px 0px rgba(0, 0, 0, 0),
      0px 23px 9px 0px rgba(0, 0, 0, 0), 0px 13px 8px 0px rgba(0, 0, 0, 0.01),
      0px 6px 6px 0px rgba(0, 0, 0, 0.02), 0px 1px 3px 0px rgba(0, 0, 0, 0.02);
    transition: opacity 0.3s ease-in-out;
  }
}

.fixedSearch {
  padding: 18px 36px;

  &::after {
    opacity: 1;
    height: 100%;
    width: calc(100% + 2px);
  }
}

.fixedTabs div[class="ant-tabs-nav"] {
  position: fixed;
  top: 450px;
  z-index: 10;
}

.fixedTabs .tree {
  margin-top: 62px;
}

@media screen and (min-width: 1400px) {
  .fixedTabs div[class="ant-tabs-nav"] {
    width: 100%;
    max-width: 1014px;
  }
}
