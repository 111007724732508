.space {
  width: 100%;
  margin-bottom: 14px;
}

.spaceBig div:nth-of-type(2) {
  width: 80%;
}

.spaceSmall div:first-of-type {
  width: 100%;
}

.notSearch div:nth-of-type(2) {
  width: fit-content;
  margin-left: auto;
}

.notSearch > div {
  justify-content: flex-end;
  display: flex;
}

.btn.add {
  min-width: 12.1875rem;
  justify-content: center;
}

.container {
  position: relative;
}

.tagContainer {
  margin-top: 4px;
  gap: 10px;
  display: inline-flex;
}

.table {
  & > div > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 50vh;
  }
}

.action {
  cursor: pointer;
  padding-top: 10px;
  box-sizing: content-box;
  width: 1.125rem;
  height: 1.125rem;
}
